$ARROW_DIRECTIONS: (
  down: top,
  up: bottom,
  left: right,
  right: left,
);

@mixin arrow($direction) {
  @if not map-has-key($ARROW_DIRECTIONS, $direction) {
    @error '[balloon] The $direction parameter must be one of: `#{map-keys($DIRECTIONS)}, `#{$direction}` was given.';
  }

  width: 0;
  height: 0;
  border: $balloon-arrow-size solid transparent;
  border-#{map-get($ARROW_DIRECTIONS, $direction)}-color: var(--balloon-color);
}

@mixin base-effects() {
  opacity: 0;
  pointer-events: none;
  transition: all 0.18s ease-out 0.18s;
}

@mixin no-effects() {
  transition: none;
}

@mixin normalized-text() {
  text-indent: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-weight: normal;
  font-style: normal;
  text-shadow: none;
  font-size: var(--balloon-font-size);
}
