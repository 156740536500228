$primary-one: rgb(200, 16, 46) !default;
$primary-two: #444 !default;
$primary-three: rgb(167, 168, 170) !default;

//PRIMARIES
$primary-one: $primary-one;
$eq-blue: $primary-two;
$eq-lake: $primary-two;
$eq-sky: transparent !default;
$eq-lime: transparent !default;
$eq-navy: $eq-blue;

$eq-charcoal: $primary-two;
$eq-50-grey: lighten($primary-two, 50%);
$eq-30-grey: lighten($primary-two, 30%);
$eq-07-grey: lighten($primary-two, 7%);
$eq-white: rgb(255, 255, 255) !default;
$eq-black: rgb(68, 68, 68) !default;


$tangerine: #f35b3f;
$gold: gold;

// Z-indexes
//--------------------------------------------

$z-index-toast: 1051 !default;
$z-index-tooltip: 100 !default;
$z-index-menu: 100 !default;
$z-index-calendar-pane: 100 !default;
$z-index-select: 90 !default;
$z-index-dialog: 80 !default;
$z-index-bottom-sheet: 70 !default;
$z-index-scroll-mask: 50 !default;
$z-index-scroll-mask-bar: 65 !default;
$z-index-sidenav: 60 !default;
$z-index-backdrop: 50 !default;
$z-index-fab: 20 !default;
$z-index-progress-circular: 2 !default; // Used to fix animation bug in Chrome


// Theme;
$theme-name: 'Admin Html';
$theme-slug: 'admin-html';
$theme-breakpoint: 960px;
$transition-duration: 0.3s;
$transition-function: ease-in-out;
$spacing-unit: 24px;

// Content
$wrapper-top-padding: 25px;

$nav-link-color: #888;
$nav-link-hover-color: #f7fafc;
$nav-sub-link-color: #888;
$nav-sub-link-hover-color: #514d6a;
$nav-icon-color: #d0d6df;


// Sidebar
$sidebar-width: 16.25rem;
$sidebar-bg: #fff;


//$primary-darken: darken($primary-one, 10%);

$lighten-grey: lighten($eq-50-grey, 5%);
$charcoal: lighten($eq-charcoal, 20%);


$collapse-sidebar-width: 50px;
$grey-color-2: #ffffff;
$grey-color: #ffffff;
$transitions-speed: 500ms;
$font-color: $primary-two;


//
// Color system
//

// Theme Colors
$color-scheme: $primary-two !default;
$color-scheme-hover: darken($color-scheme, 7%) !default;
$color-scheme-dark: $primary-three !default;
$color-scheme-dark-hover: #4a5368 !default;
$content-color: #555 !default;
$theme-border-color: #C2C2C2 !default;

// Variables
//
// Variables should follow the `$component-state-property-size` formula for
// consistent naming. Ex: $nav-link-disabled-color and $modal-content-box-shadow-xs.

//
// Color system
//

$white: #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #6c757d !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$black: #000 !default;

$grays: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$grays: map-merge(
    (
      "100": $gray-100,
      "200": $gray-200,
      "300": $gray-300,
      "400": $gray-400,
      "500": $gray-500,
      "600": $gray-600,
      "700": $gray-700,
      "800": $gray-800,
      "900": $gray-900
    ),
    $grays
);

$blue: #007bff !default;
$indigo: #6610f2 !default;
$purple: #6f42c1 !default;
$pink: #e83e8c !default;
$red: #e6614f !default;
$orange: #fb9678 !default;
$yellow: #ffcc02 !default;
$green: #38d57a !default;
$teal: #20c997 !default;
$cyan: #03a9f3 !default;

$primary: $tangerine !default;
$danger: #c26565 !default;
$success: $green !default;
$warning: #ffcc02 !default;
$info: #03a9f3 !default;

$colors: (
  blue: $blue,
  indigo: $indigo,
  purple: $purple,
  pink: $pink,
  red: $red,
  orange: $orange,
  yellow: $yellow,
  green: $green,
  teal: $teal,
  cyan: $cyan,
  white: $white,
  gray: $gray-600,
  gray-dark: $gray-800,
  violet: #9c27b0,
  brown: #795548,
  light-gray: #f9f9f9,
  slate: #607d8b,
  facebook: #3b5998,
  twitter: #55acee,
  googleplus: #dd4b39,
  linkedin: #007bb6,
  instagram: #3f729b,
  pinterest: #cb2027,
  dribbble: #ea4c89,
  youtube: #b00,
) !default;

$theme-colors: (
  color-scheme: $color-scheme,
  color-scheme-dark: $color-scheme-dark,
  content-color: $content-color,
  content: $content-color,
  primary: $color-scheme,
  //secondary: $gray-600,
  secondary: $primary-two,
  success: $success,
  info: $cyan,
  warning: $yellow,
  danger: $red,
  light: $gray-100,
  dark: $gray-800
) !default;

// Set a specific jump point for requesting color jumps
$theme-color-interval: 8% !default;

// Options
//
// Quickly modify global styling by enabling or disabling optional features.

$enable-rounded: true !default;
$enable-shadows: true !default;
$enable-gradients: false !default;
$enable-transitions: true !default;
$enable-hover-media-query: false !default;
$enable-grid-classes: true !default;
$enable-print-styles: true !default;

// Spacing
//
// Control the default styling of most Bootstrap elements by modifying these
// variables. Mostly focused on spacing.
// You can add more entries to the $spacers map, should you need more variation.

$spacer: 1rem !default;
$spacers: (
  0: 0,
  1: ($spacer * .25),
  2: ($spacer * .5),
  3: $spacer,
  4: ($spacer * 1.5),
  5: ($spacer * 3)
) !default;

// This variable affects the `.h-*` and `.w-*` classes.
$sizes: (
  25: 25%,
  50: 50%,
  75: 75%,
  100: 100%
) !default;

// Body
//
// Settings for the `<body>` element.

$body-bg: #f2f2f2 !default;
$body-color: $content-color !default;

// Links
//
// Style anchor elements.

$link-color: $color-scheme !default;
$link-decoration: none !default;
$link-hover-color: $color-scheme-hover !default;
$link-hover-decoration: none !default;

// Grid breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px
) !default;
@include _assert-ascending($grid-breakpoints, "$grid-breakpoints");
@include _assert-starts-at-zero($grid-breakpoints);

// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px
) !default;
@include _assert-ascending($container-max-widths, "$container-max-widths");

// Grid columns
//
// Set the number of columns and specify the width of the gutters.

$grid-columns: 12 !default;
$grid-gutter-width: 30px !default;

// Components
//
// Define common padding and border radius sizes and more.

$line-height-lg: 1.5 !default;
$line-height-sm: 1.5 !default;

$border-width: 1px !default;

$border-radius: .25rem !default;
$border-radius-lg: .3rem !default;
$border-radius-sm: .2rem !default;

$component-active-color: $white !default;
$component-active-bg: map-get($theme-colors, "primary") !default;

$caret-width: .3em !default;

$transition-base: all .2s ease-in-out !default;
$transition-fade: opacity .15s linear !default;
$transition-collapse: height .35s ease !default;

// Fonts
//
// Font, line-height, and color for body text, headings, and more.

$secondary-font-family: 'Roboto', sans-serif;
$tertiary-font-family: 'Helvetica Neue', sans-serif;
$font-family-sans-serif: Roboto, "Helvetica Neue", sans-serif !default;
$font-family-base: $font-family-sans-serif !default;

$font-size-base-px: 14px !default;
$font-size-base: 0.875rem !default;
$font-size-lg: 1rem !default; // Assumes the browser default, typically `16px`
$font-size-sm: .75rem !default;
$font-size-xs: .625rem !default;

$font-weight-normal: normal !default;
$font-weight-bold: bold !default;

$font-weight-base: $font-weight-normal !default;
$line-height-base: 1.57142857 !default;

$h1-font-size: 2.625rem !default; //42px
$h2-font-size: 2.25rem !default; // 36px
$h3-font-size: 1.875rem !default; // 30px
$h4-font-size: 1.500rem !default; // 24px
$h5-font-size: 1.125rem !default; // 18px
$h6-font-size: 0.875rem !default; // 14px

$headings-margin-bottom: ($spacer / 2) !default;
$headings-font-family: $font-family-base !default;
$headings-font-weight: 600 !default;
$headings-line-height: 1.1 !default;
$headings-color: #333 !default;

$display1-size: 6rem !default;
$display2-size: 5.5rem !default;
$display3-size: 4.5rem !default;
$display4-size: 3.5rem !default;

$display1-weight: 300 !default;
$display2-weight: 300 !default;
$display3-weight: 300 !default;
$display4-weight: 300 !default;
$display-line-height: $headings-line-height !default;

$lead-font-size: 1.25rem !default;
$lead-font-weight: 300 !default;

$small-font-size: 80% !default;

$text-muted: $gray-600 !default;

$blockquote-small-color: $gray-600 !default;
$blockquote-font-size: ($font-size-base * 1.28571429) !default;

$hr-border-color: rgba($black, .1) !default;
$hr-border-width: $border-width !default;

$mark-padding: .2em !default;

$dt-font-weight: $font-weight-bold !default;

$kbd-box-shadow: inset 0 -.1rem 0 rgba($black, .25) !default;
$nested-kbd-font-weight: $font-weight-bold !default;

$list-inline-padding: 5px !default;

$mark-bg: #fcf8e3 !default;

// Tables
//
// Customizes the `.transaction-table` component with basic values, each used across all transaction-table variations.

$table-cell-padding: 0.571428571em !default;
$table-sm-cell-padding: .3rem !default;

$table-bg: transparent !default;
$table-accent-bg: $nav-link-hover-color !default;
$table-hover-bg: darken($table-accent-bg, 3%) !default;
$table-active-bg: $table-hover-bg !default;

$table-border-width: $border-width !default;
$table-border-color: lighten($theme-border-color, 7%) !default;

$table-head-bg: $gray-200 !default;
$table-head-color: lighten($theme-border-color, 7%) !default;

$table-inverse-bg: $gray-900 !default;
$table-inverse-accent-bg: rgba($white, .05) !default;
$table-inverse-hover-bg: rgba($white, .075) !default;
$table-inverse-border-color: rgba(255, 255, 255, 0.1) !default;
$table-inverse-color: $body-bg !default;

// Buttons
//
// For each of Bootstrap's buttons, define text, background and border color.

$input-btn-padding-y: .5625rem !default;
$input-btn-padding-x: 1.2em !default;
$input-btn-line-height: 1.57142857 !default;

$input-btn-font-size-sm: 11px !default;
$input-btn-padding-y-sm: 0 !default;
$input-btn-padding-x-sm: .5rem !default;
$input-btn-line-height-sm: 2 !default;

$input-btn-font-size-lg: 1.125rem !default;
$input-btn-padding-y-lg: .3125rem !default;
$input-btn-padding-x-lg: 1.5rem !default;
$input-btn-line-height-lg: 2 !default;

$btn-font-family: $headings-font-family;
$btn-font-size: $font-size-base !default;
$btn-font-weight: 600 !default;
$btn-box-shadow: inset 0 1px 0 rgba($white, .15), 0 1px 1px rgba($black, .075) !default;
$btn-focus-box-shadow: 0 0 0 2px rgba($black, .125) !default;
$btn-active-box-shadow: inset 0 3px 5px rgba($black, .125) !default;

$btn-link-disabled-color: $gray-600 !default;

$btn-block-spacing-y: .5rem !default;

// Allows for customizing button radius independently from global border radius
$btn-border-radius: $border-radius !default;
$btn-border-radius-lg: $border-radius-lg !default;
$btn-border-radius-sm: $border-radius-sm !default;

$btn-transition: all $transition-duration $transition-function !default;

// Forms

$input-padding-x: 1rem !default;
$input-padding-y: 0.5625rem !default;
$input-line-height: $input-btn-line-height !default;

$input-bg: $white !default;
$input-bg-disabled: #eee !default;

$input-color: #74708d !default;
$input-border-color: #e4e9f0 !default;
$input-btn-border-width: $border-width !default; // For form controls and buttons
$input-box-shadow: inset 0 1px 1px rgba($black, .075) !default;

$input-border-radius: $border-radius !default;
$input-border-radius-lg: $border-radius-lg !default;
$input-border-radius-sm: $border-radius-sm !default;

$input-focus-bg: $input-bg !default;
$input-focus-border-color: map-get($theme-colors, "info") !default;
$input-focus-box-shadow: $input-box-shadow, 0 0 0 3px rgba($input-focus-border-color, .2) !default;
$input-focus-color: $input-color !default;

$input-placeholder-color: #bbb !default;

$input-height-border: $input-btn-border-width * 2 !default;

$input-height-inner: ($font-size-base * $input-btn-line-height) + ($input-btn-padding-y * 2) !default;
$input-height: calc(#{$input-height-inner} + #{$input-height-border}) !default;

$input-height-inner-sm: ($font-size-sm * $input-btn-line-height-sm) + ($input-btn-padding-y-sm * 2) !default;
$input-height-sm: calc(#{$input-height-inner-sm} + #{$input-height-border}) !default;

$input-height-inner-lg: ($font-size-sm * $input-btn-line-height-lg) + ($input-btn-padding-y-lg * 2) !default;
$input-height-lg: calc(#{$input-height-inner-lg} + #{$input-height-border}) !default;

$input-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s !default;

$form-text-margin-top: .25rem !default;

$form-check-margin-bottom: .5rem !default;
$form-check-input-gutter: 1.25rem !default;
$form-check-input-margin-y: .25rem !default;
$form-check-input-margin-x: .25rem !default;

$form-check-inline-margin-x: .75rem !default;

$form-group-margin-bottom: 1.5625rem !default;

$input-group-addon-bg: #eee !default;
$input-group-addon-border-color: $input-border-color !default;

$custom-control-gutter: 1.5rem !default;
$custom-control-spacer-x: 1rem !default;
$custom-control-spacer-y: .25rem !default;

$custom-control-indicator-size: 1rem !default;
$custom-control-indicator-bg: #ddd !default;
$custom-control-indicator-bg-size: 50% 50% !default;
$custom-control-indicator-box-shadow: inset 0 .25rem .25rem rgba($black, .1) !default;

$custom-control-indicator-disabled-bg: $gray-200 !default;
$custom-control-description-disabled-color: $gray-600 !default;

$custom-control-indicator-checked-color: $white !default;
$custom-control-indicator-checked-bg: map-get($theme-colors, "primary") !default;
$custom-control-indicator-checked-box-shadow: none !default;

$custom-control-indicator-focus-box-shadow: 0 0 0 1px $body-bg, 0 0 0 3px map-get($theme-colors, "primary") !default;

$custom-control-indicator-active-color: $white !default;
$custom-control-indicator-active-bg: lighten(map-get($theme-colors, "primary"), 35%) !default;
$custom-control-indicator-active-box-shadow: none !default;

$custom-checkbox-indicator-border-radius: $border-radius !default;
$custom-checkbox-indicator-icon-checked: str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='#{$custom-control-indicator-checked-color}' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E"), "#", "%23") !default;

$custom-checkbox-indicator-indeterminate-bg: map-get($theme-colors, "primary") !default;
$custom-checkbox-indicator-indeterminate-color: $custom-control-indicator-checked-color !default;
$custom-checkbox-indicator-icon-indeterminate: str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3E%3Cpath stroke='#{$custom-checkbox-indicator-indeterminate-color}' d='M0 2h4'/%3E%3C/svg%3E"), "#", "%23") !default;
$custom-checkbox-indicator-indeterminate-box-shadow: none !default;

$custom-radio-indicator-border-radius: 50% !default;
$custom-radio-indicator-icon-checked: str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='#{$custom-control-indicator-checked-color}'/%3E%3C/svg%3E"), "#", "%23") !default;

$custom-select-padding-y: .375rem !default;
$custom-select-padding-x: .75rem !default;
$custom-select-height: $input-height !default;
$custom-select-indicator-padding: 1rem !default; // Extra padding to account for the presence of the background-image based indicator
$custom-select-line-height: $input-btn-line-height !default;
$custom-select-color: $input-color !default;
$custom-select-disabled-color: $gray-600 !default;
$custom-select-bg: $white !default;
$custom-select-disabled-bg: $gray-200 !default;
$custom-select-bg-size: 8px 10px !default; // In pixels because image dimensions
$custom-select-indicator-color: #333 !default;
$custom-select-indicator: str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='#{$custom-select-indicator-color}' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E"), "#", "%23") !default;
$custom-select-border-width: $input-btn-border-width !default;
$custom-select-border-color: $input-border-color !default;
$custom-select-border-radius: $border-radius !default;

$custom-select-focus-border-color: lighten(map-get($theme-colors, "primary"), 25%) !default;
$custom-select-focus-box-shadow: inset 0 1px 2px rgba($black, .075), 0 0 5px rgba($custom-select-focus-border-color, .5) !default;

$custom-select-font-size-sm: 75% !default;
$custom-select-height-sm: $input-height-sm !default;

$custom-file-height: 2.5rem !default;
$custom-file-width: 14rem !default;
$custom-file-focus-box-shadow: 0 0 0 .075rem $white, 0 0 0 .2rem map-get($theme-colors, "primary") !default;

$custom-file-padding-y: 1rem !default;
$custom-file-padding-x: .5rem !default;
$custom-file-line-height: 1.5 !default;
$custom-file-color: $gray-700 !default;
$custom-file-bg: $white !default;
$custom-file-border-width: $border-width !default;
$custom-file-border-color: $input-border-color !default;
$custom-file-border-radius: $border-radius !default;
$custom-file-box-shadow: inset 0 .2rem .4rem rgba($black, .05) !default;
$custom-file-button-color: $custom-file-color !default;
$custom-file-button-bg: $gray-200 !default;
$custom-file-text: (
  en: "Browse"
) !default;

// Form validation icons
$form-feedback-valid-color: map-get($theme-colors, "success") !default;
$form-feedback-invalid-color: map-get($theme-colors, "danger") !default;

// Dropdowns
//
// Dropdown menu container and contents.

$dropdown-min-width: 10rem !default;
$dropdown-padding-y: .5rem !default;
$dropdown-spacer: .125rem !default;
$dropdown-bg: $white !default;
$dropdown-border-color: rgba($black, .15) !default;
$dropdown-border-width: $border-width !default;
$dropdown-divider-bg: $gray-200 !default;
$dropdown-box-shadow: 0 .5rem 1rem rgba($black, .175) !default;

$dropdown-link-color: #888 !default;
$dropdown-link-hover-color: darken($dropdown-link-color, 5%) !default;
$dropdown-link-hover-bg: $gray-100 !default;

$dropdown-link-active-color: $dropdown-link-hover-color !default;
$dropdown-link-active-bg: $dropdown-link-hover-bg !default;

$dropdown-link-disabled-color: $gray-600 !default;

$dropdown-item-padding-y: .25rem !default;
$dropdown-item-padding-x: 1.42857142857em !default;

$dropdown-header-color: $gray-600 !default;

// Z-index master list
//
// Warning: Avoid customizing these values. They're used for a bird's eye view
// of components dependent on the z-axis and are designed to all work together.

$zindex-dropdown: 1000 !default;
$zindex-sticky: 1020 !default;
$zindex-fixed: 1030 !default;
$zindex-modal-backdrop: 1040 !default;
$zindex-modal: 1050 !default;
$zindex-popover: 1060 !default;
$zindex-tooltip: 1070 !default;

// Navs

$nav-link-padding-y: .5rem !default;
$nav-link-padding-x: 1rem !default;
$nav-link-disabled-color: $gray-600 !default;

$nav-tabs-border-color: #ddd !default;
$nav-tabs-border-width: $border-width !default;
$nav-tabs-border-radius: $border-radius !default;
$nav-tabs-link-hover-border-color: $gray-200 !default;
$nav-tabs-link-active-color: $gray-700 !default;
$nav-tabs-link-active-bg: $body-bg !default;
$nav-tabs-link-active-border-color: #ddd !default;

$nav-pills-border-radius: $border-radius !default;
$nav-pills-link-active-color: $component-active-color !default;
$nav-pills-link-active-bg: $component-active-bg !default;

// Navbar

$navbar-padding-y: ($spacer / 2) !default;
$navbar-padding-x: $spacer !default;

$navbar-brand-font-size: $font-size-lg !default;
// Compute the navbar-brand padding-y so the navbar-brand will have the same height as navbar-text and nav-link
$nav-link-height: $navbar-brand-font-size * $line-height-base !default;
$navbar-brand-height: ($font-size-base * $line-height-base + $nav-link-padding-y * 2) !default;
$navbar-brand-padding-y: ($navbar-brand-height - $nav-link-height) / 2 !default;

$navbar-toggler-padding-y: .25rem !default;
$navbar-toggler-padding-x: .75rem !default;
$navbar-toggler-font-size: $font-size-lg !default;
$navbar-toggler-border-radius: $btn-border-radius !default;

$navbar-dark-color: rgba($white, .5) !default;
$navbar-dark-hover-color: rgba($white, .75) !default;
$navbar-dark-active-color: rgba($white, 1) !default;
$navbar-dark-disabled-color: rgba($white, .25) !default;
$navbar-dark-toggler-icon-bg: str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='#{$navbar-dark-color}' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E"), "#", "%23") !default;
$navbar-dark-toggler-border-color: rgba($white, .1) !default;

$navbar-light-color: rgba($black, .5) !default;
$navbar-light-hover-color: rgba($black, .7) !default;
$navbar-light-active-color: rgba($black, .9) !default;
$navbar-light-disabled-color: rgba($black, .3) !default;
$navbar-light-toggler-icon-bg: str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='#{$navbar-light-color}' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E"), "#", "%23") !default;
$navbar-light-toggler-border-color: rgba($black, .1) !default;

// Pagination

$pagination-padding-y: .5rem !default;
$pagination-padding-x: .75rem !default;
$pagination-padding-y-sm: .25rem !default;
$pagination-padding-x-sm: .5rem !default;
$pagination-padding-y-lg: .75rem !default;
$pagination-padding-x-lg: 1.5rem !default;
$pagination-line-height: 1.25 !default;

$pagination-color: $link-color !default;
$pagination-bg: $white !default;
$pagination-border-width: $border-width !default;
$pagination-border-color: #ddd !default;

$pagination-hover-color: $link-hover-color !default;
$pagination-hover-bg: $gray-200 !default;
$pagination-hover-border-color: #ddd !default;

$pagination-active-color: $white !default;
$pagination-active-bg: $pagination-color !default;
$pagination-active-border-color: $pagination-color !default;

$pagination-disabled-color: $gray-600 !default;
$pagination-disabled-bg: $white !default;
$pagination-disabled-border-color: #ddd !default;

// Jumbotron

$jumbotron-padding: 2rem !default;
$jumbotron-bg: $gray-200 !default;

// Cards

$card-spacer-y: .75rem !default;
$card-spacer-x: 1.25rem !default;
$card-border-width: 1px !default;
$card-border-radius: $border-radius !default;
$card-border-color: rgba($black, .125) !default;
$card-inner-border-radius: calc(#{$card-border-radius} - #{$card-border-width}) !default;
$card-cap-bg: rgba($black, .03) !default;
$card-bg: $white !default;

$card-img-overlay-padding: 1.25rem !default;

$card-deck-margin: calc($grid-gutter-width / 2) !default;

$card-columns-count: 3 !default;
$card-columns-gap: 1.25rem !default;
$card-columns-margin: $card-spacer-y !default;

// Tooltips

$tooltip-max-width: 200px !default;
$tooltip-color: $white !default;
$tooltip-bg: $black !default;
$tooltip-opacity: .9 !default;
$tooltip-padding-y: 3px !default;
$tooltip-padding-x: 8px !default;
$tooltip-margin: 0 !default;

$tooltip-arrow-width: 5px !default;
$tooltip-arrow-height: 5px !default;
$tooltip-arrow-color: $tooltip-bg !default;

// Popovers

$popover-inner-padding: 1px !default;
$popover-bg: $white !default;
$popover-max-width: 276px !default;
$popover-border-width: $border-width !default;
$popover-border-color: rgba($black, .2) !default;
$popover-box-shadow: 0 5px 10px rgba($black, .2) !default;

$popover-header-bg: darken($popover-bg, 3%) !default;
$popover-header-color: $headings-color !default;
$popover-header-padding-y: 8px !default;
$popover-header-padding-x: 14px !default;

$popover-body-color: $body-color !default;
$popover-body-padding-y: 9px !default;
$popover-body-padding-x: 14px !default;

$popover-arrow-width: 10px !default;
$popover-arrow-height: 5px !default;
$popover-arrow-color: $popover-bg !default;

$popover-arrow-outer-width: ($popover-arrow-width + 1) !default;
$popover-arrow-outer-color: fade-in($popover-border-color, .05) !default;

// Badges

$badge-color: $white !default;
$badge-font-size: 75% !default;
$badge-font-weight: $font-weight-bold !default;
$badge-padding-y: .25em !default;
$badge-padding-x: .4em !default;

$badge-pill-padding-x: .6em !default;
// Use a higher than normal value to ensure completely rounded edges when
// customizing padding or font-size on labels.
$badge-pill-border-radius: 10rem !default;

// Modals

// Padding applied to the modal body
//$modal-inner-padding: 15px !default;
$modal-inner-padding: 1.33rem !default;

$modal-dialog-margin: 10px !default;
$modal-dialog-margin-y-sm-up: 30px !default;

$modal-title-line-height: $line-height-base !default;

$modal-content-bg: $white !default;
$modal-content-border-color: #ddd !default;
$modal-content-border-width: $border-width !default;
$modal-content-box-shadow-xs: none !default;
$modal-content-box-shadow-sm-up: none !default;

$modal-backdrop-bg: $black !default;
$modal-backdrop-opacity: .5 !default;
$modal-header-border-color: $gray-200 !default;
$modal-footer-border-color: $modal-header-border-color !default;
$modal-header-border-width: $modal-content-border-width !default;
$modal-footer-border-width: $modal-header-border-width !default;
$modal-header-padding: 1.33rem $modal-inner-padding !default;

$modal-lg: 800px !default;
$modal-md: 500px !default;
$modal-sm: 300px !default;

$modal-transition: transform .3s ease-out !default;

// Alerts
//
// Define alert colors, border radius, and padding.

$alert-padding-y: .75rem !default;
$alert-padding-x: 1.25rem !default;
$alert-margin-bottom: 1rem !default;
$alert-border-radius: $border-radius !default;
$alert-link-font-weight: $font-weight-bold !default;
$alert-border-width: $border-width !default;

// Progress bars

$progress-height: 1rem !default;
$progress-font-size: .75rem !default;
$progress-bg: $gray-200 !default;
$progress-border-radius: $border-radius !default;
$progress-box-shadow: inset 0 .1rem .1rem rgba($black, .1) !default;
$progress-bar-color: $white !default;
$progress-bar-bg: map-get($theme-colors, "primary") !default;
$progress-bar-animation-timing: 1s linear infinite !default;
$progress-bar-transition: width .6s ease !default;

// Image thumbnails

$thumbnail-padding: .25rem !default;
$thumbnail-bg: $body-bg !default;
$thumbnail-border-width: $border-width !default;
$thumbnail-border-color: #ddd !default;
$thumbnail-border-radius: $border-radius !default;
$thumbnail-box-shadow: 0 1px 2px rgba($black, .075) !default;
$thumbnail-transition: all .2s ease-in-out !default;

// Figures

$figure-caption-font-size: 90% !default;
$figure-caption-color: $gray-600 !default;


// Close

$close-font-size: $font-size-base * 1.5 !default;
$close-font-weight: $font-weight-bold !default;
$close-color: $black !default;
$close-text-shadow: 0 1px 0 $white !default;

// Code

$code-font-size: 90% !default;
$code-padding-y: .2rem !default;
$code-padding-x: .4rem !default;
$code-color: #bd4147 !default;
$code-bg: $gray-100 !default;

$kbd-color: $white !default;
$kbd-bg: $gray-900 !default;

$pre-color: $gray-900 !default;
$pre-scrollable-max-height: 340px !default;

$transition-speed: 500ms;
$transition-speed-color: 1500ms;


$tablet-width: 768px;
$desktop-width: 1024px;
$focus-bg-color: #f5f7f6;
