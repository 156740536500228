
label {
  &.label {
    display: block;
    margin-bottom: 0px;
    //  line-height: 19px;
    font-weight: 400;
    font-size: 12px;
    //  color: #333;
    //  text-align: left;
    white-space: normal;
    padding: 0;
  }
}


.list {
  font-weight: 400;
  color: #666666;
}

