.square {
  position: relative;

  &:after {
    content: "";
    display: block;
    padding-bottom: 100%;

  }

  .content {
    position: absolute;
    height: 100%;
    width: 100%;
  }

  .polaroid {
    width: 100%;
    height: 100%;
  }

}
