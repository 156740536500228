
.btn-burger {
	cursor: pointer;

	width: 2.5rem;
	height: 2.25rem;
	border-radius: 0.25rem;

	overflow: hidden;
		
	display: flex;
	align-items: center;
	justify-content: center;

	position: relative;
		
	@include transition(background-color $btn-burger-transition);//, border-radius 150ms);

	color: #fff;
	padding: 0;
	
	&:hover , &:focus {
		color: #fff;
		background-color: $navbar-toggler-hover-bg;		
	}
	&:active {
		transform: none !important;
	}

	.bars {
		display: block;
		
		width: 50%;
		height: 2px;

		position: relative;

		color: inherit;
		background-color: currentColor;		

		@include transition(transform $btn-burger-transition, top $btn-burger-transition);

		&::before , &::after {
			content: "";
			display: block;

			position: absolute;
			left: 0;
			width: 100%;
			height: inherit;
			
			background-color: inherit;
			color: inherit;
			border-radius: inherit;

			transition: inherit;
			transform-origin: inherit;
		}

		&::before {
			top: -0.375rem;
		}
		&::after {
			top: 0.375rem;
		}
	}

	
	///
	&.burger-dots {
		width: 2.25rem;
		.bars {
			width: 0.25rem;
			height: 0.25rem;

			border-radius: 0.25rem;
		}
	}

	&.burger-steps {
		.bars {
			transform-origin: left;
		}
		&:hover .bars {
			transform: scaleX(0.75);
			&::before {
				transform: scaleX(1.25);
			}
			&::after {
				transform: scaleX(0.75);
			}
		}
	}//burger-steps



	&.burger-arrowed {
		.bars {
			transform-origin: left;
		}
		&:not(.static):hover .bars , &.static:not(.collapsed) .bars {
			&::before {
				top: 0;
				transform: rotate(-45deg) scaleX(0.66);
			}
			&::after {
				top: 0;
				transform: rotate(45deg) scaleX(0.66);
			}
		}
		&:not(.static).collapsed {
			.bars {
				transform-origin: right;
			}
			&:hover .bars {
				&::before {
					top: 0;
					transform: rotate(45deg) scaleX(0.66);
				}
				&::after {
					top: 0;
					transform: rotate(-45deg) scaleX(0.66);
				}
			}
		}

	}

	/////////


	&.burger-minus {
		&:not(.static):hover .bars , &.static:not(.collapsed) .bars {
			&::before , &::after {
				top: 0;
			}
		}
	}

	&.burger-times {
		.bars {
			transform-origin: center;
		}
		&:not(.static):hover .bars , &.static:not(.collapsed) .bars {
			visibility: hidden;
			&::before {
				top: 0;
				visibility: visible;
				transform: rotate(-45deg) scaleX(0.9);
			}
			&::after {
				top: 0;
				visibility: visible;
				transform: rotate(45deg) scaleX(0.9);
			}
		}
	}


	&.burger-compact {
		&:not(.static):hover .bars , &.static:not(.collapsed) .bars {
			transform-origin: center;
			transform: scaleX(0.8);
			&::before {
				top: -0.25rem;
			}
			&::after {
				top: 0.25rem;
			}
		}
	}

}
