/*!
 * Ace Admin Template v4.0.0
 * Copyright 2013-2021
 * You need a commercial license to use this product
 * https://bit.ly/35ciMLb
 */


@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";


@import "./vendors/ace/basictable";
@import "./vendors/ace/@page-style";

@import "/src/scss/vendors/ace/scss/ace-features";
@import "/src/scss/vendors/ace/scss/variables";
@import "/src/scss/vendors/ace/scss/functions";
@import "/src/scss/vendors/ace/scss/button-colors";
@import "/src/scss/vendors/ace/scss/variables-more";
@import "/src/scss/vendors/ace/scss/font-size";
@import "/src/scss/vendors/ace/scss/navbar-themes-variables";
@import "/src/scss/vendors/ace/scss/sidebar-themes-variables";
@import "/src/scss/vendors/ace/scss/base";
@import "/src/scss/vendors/ace/scss/typography";
@import "/src/scss/vendors/ace/scss/colors-text";
@import "/src/scss/vendors/ace/scss/colors-background";
@import "/src/scss/vendors/ace/scss/spacing";
@import "/src/scss/vendors/ace/scss/scrollbar";
@import "/src/scss/vendors/ace/scss/navbar";
@import "/src/scss/vendors/ace/scss/navbar-toggler";
@import "/src/scss/vendors/ace/scss/navbar-link";
@import "/src/scss/vendors/ace/scss/navbar-mobile";
@import "/src/scss/vendors/ace/scss/sticky";
@import "/src/scss/vendors/ace/scss/sidebar";
@import "/src/scss/vendors/ace/scss/sidebar-scrollbar";
@import "/src/scss/vendors/ace/scss/sidebar-expandable";
@import "/src/scss/vendors/ace/scss/sidebar-hoverable";
@import "/src/scss/vendors/ace/scss/sidebar-hideable";
@import "/src/scss/vendors/ace/scss/sidebar-liststyle";
//@import "/src/scss/vendors/ace/scss/sidebar-mobile";
//@import "/src/scss/vendors/ace/scss/sidebar-horizontal";
@import "/src/scss/vendors/ace/scss/sidebar-active";
@import "/src/scss/vendors/ace/scss/sidebar-spaced";
@import "/src/scss/vendors/ace/scss/ace-default-themes.scss";////include only the sidebar/navbar theme you want
@import "/src/scss/vendors/ace/scss/ace-themes.scss";//include all themes ... not recommended
@import "/src/scss/vendors/ace/scss/sidebar-themes-extra";
//@import "/src/scss/vendors/ace/scss/footer";
@import "/src/scss/vendors/ace/scss/fixed";
@import "/src/scss/vendors/ace/scss/content-nav";
@import "/src/scss/vendors/ace/scss/card";
@import "/src/scss/vendors/ace/scss/pagination";//comes before buttons, so that buttons can override it
@import "/src/scss/vendors/ace/scss/button";
@import "/src/scss/vendors/ace/scss/form";
@import "/src/scss/vendors/ace/scss/form-checkbox";
@import "/src/scss/vendors/ace/scss/form-file";
@import "/src/scss/vendors/ace/scss/badge";
@import "/src/scss/vendors/ace/scss/dropdown";
// @import "/src/scss/vendors/ace/scss/tab";
// @import "/src/scss/vendors/ace/scss/accordion";
// @import "/src/scss/vendors/ace/scss/tooltip";
// @import "/src/scss/vendors/ace/scss/alert";
//@import "/src/scss/vendors/ace/scss/aside-modal";//breaks modals
// @import "/src/scss/vendors/ace/scss/table";
// @import "/src/scss/vendors/ace/scss/misc";
// @import "/src/scss/vendors/ace/scss/icon-animation";
//@import "/src/scss/vendors/ace/scss/plugins/treeview";
//@import "/src/scss/vendors/ace/scss/plugins/bootstraptable";
//@import "/src/scss/vendors/ace/scss/plugins/datatables";
//@import "/src/scss/vendors/ace/scss/plugins/jqgrid";
//@import "/src/scss/vendors/ace/scss/plugins/chosen";
//@import "/src/scss/vendors/ace/scss/plugins/select2";
//@import "/src/scss/vendors/ace/scss/plugins/wizard";
//@import "/src/scss/vendors/ace/scss/plugins/calendar";
//@import "/src/scss/vendors/ace/scss/plugins/date";
//@import "/src/scss/vendors/ace/scss/plugins/spin";
//@import "/src/scss/vendors/ace/scss/plugins/slider";
//@import "/src/scss/vendors/ace/scss/plugins/misc";
//dropzone, summernote, wysiwyg, tagsinput, markdown, rating, typeahead

