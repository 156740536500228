//font weights
$font-bold: 700;
$font-semibold: 600;// you might want to change this according to your font if doesn't support semi bold font weight
$font-medium: 500;
$font-normal: 400;
$font-light: 300;


//font sizes
$base-font-size: 1rem;


$badge-font-size: 0.75em;
$badge-sm-font-size: 0.7em;
$badge-lg-font-size: 0.9em;

$btn-bold-font-size: 0.9em;


$card-toolbar-btn-font-size: 1.075rem;

$dropdown-font-size: 0.95rem;
$dropdown-btn-font-size: 0.9375rem;//.dropdown-item.btn


$chosen-font-size: $base-font-size;
$chosen-input-font-size: $base-font-size;

$select2-font-size: 1rem;


$datepicker-font-size: $base-font-size;


$jqgrid-font-size: 1rem;
$jqgrid-btn-font-size: 1rem;
$jqgrid-title-font-size: 1.25rem;


$summernote-btn-font-size: 1rem;
$taginput-font-size: 1rem;

$wizard-step-circle-text-size: 1.125rem;
$wizard-step-circle-hover-text-size: 1.325rem;

