@import "variables";
@import "base";
@import "material-design";
@import "scrollbar";
@import "typography";
@import "colors";
@import "fonts";
@import "ag-grid";
@import "ace";
@import "cards";
@import "helpers";
//override
@import "balloon";
@import "tags";
@import "responsive_square";
@import "ngx-bootstrap/datepicker/bs-datepicker.scss";
@import "../vendors/pacejs/minimal";

.bg-image {
  background-size: 10px 10px;
  background-image: linear-gradient(to right, #f5f7f6 1px, transparent 1px), linear-gradient(to bottom, #f5f7f6 1px, transparent 1px);
}

.c-value {
  padding: 6px 10px 0 4px;
}

.c-label,
.c-value {
  border-top: 1px dotted #d5e4f1;

  display: flex;
  align-items: center;
}

.c-label {
  padding: 6px 10px 6px 4px;
  color: #667e99;
}
