body {
  * {
    h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
      font-weight: 300;
      font-family: $font-family-sans-serif;
    }
  }
}

small {
  font-size: 0.75em;
}

html, body {
  margin: 0;
  height: 100%;
  background-color: #ffffff;

  * {
    @include scrollbar();
  }
}

hr {
  &.border-0 {
    border: 0;
  }
}

.transform-none {
  transform: none !important;
}


dt {
  font-weight: unset;
}

a {
  transition: $transition-base;
}

a[href] {
  text-decoration: underline;
}

.text-decoration-none {
  a[href] {
    text-decoration: none !important;
  }
}

.text-underline {
  text-decoration: underline !important;
}

.text-decoration-line-through {
  text-decoration: line-through !important;

}

.text-ellipsis,
.truncate {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

header.header {
  width: 100%;
  display: block;
  border-bottom: none;
  padding: 8px 0;
  font-size: 20px;
  font-weight: 300;
  color: #232323;
  margin: 10px 0px 20px;
  border-bottom: 1px dashed #cfcfcf;
}

h1 {
  letter-spacing: -1px;
  margin: 10px 0;
}

h2 {
  letter-spacing: -1px;
  margin: 20px 0;
  line-height: normal;
}

h3 {
  display: block;
  font-weight: 400;
  margin: 20px 0;
  line-height: normal;
}

h4 {
  line-height: normal;
}

h5 {
  font-weight: 300;
  margin: 10px 0;
  line-height: normal;
}

.lead {
  margin-bottom: 18px;
  font-size: 14px;
  font-weight: 300;
  line-height: 1.4;
}

.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}


.text-error {
  color: $danger !important;
}

.text-warning {
  color: $warning !important;
}

.text-tangerine {
  color: $tangerine !important;
}


@include media-breakpoint-down('sm') {

  h2, .h2 {
    font-size: 2em;
  }
}


.small, small {
  color: $text-muted;

  &.list-icon {
    font-size: 18px;
  }
}

.icon-muted {
  color: $nav-icon-color;
}


.heading-font-family {
  font-family: $headings-font-family;
}

.content-font-family {
  font-family: $font-family-base;
}

.sub-heading-font-family {
  font-family: $tertiary-font-family;
}

dd {
  margin-bottom: 5px;
}
