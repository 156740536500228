@import "../scss/variables";

.bg-tangerine {
  background-color: $tangerine !important;
}

.pdf-logo-color {
  color: #F40F02 !important;
}

.text-50-grey {
  color: $eq-50-grey !important;
}


.text-stroke {
  text-shadow: 0 0 3px $tangerine;
}


.text-gold {
  color: $gold !important;
}

.text-black-20 {
  color: lighten(#000000, 80%);
}

.text-lt-blue {
  color: lightblue;
}

.text-danger {
  color: $danger;
}

///* Colors
//========================*/
//@each $col, $val in $theme-colors {
//  .badge-#{''+$col},
//  .progress-bar-#{''+$col},
//  .bg-#{''+$col} {
//    background-color: $val !important;
//    border-color: $val !important;
//    //color: #fff;
//  }
//
//  .bg-#{''+$col}-dark {
//    background-color: darken($val, 10%);
//    border-color: darken($val, 10%);
//  }
//
//  .bg-#{''+$col}-light {
//    background-color: lighten($val, 15%);
//    border-color: lighten($val, 15%);
//  }
//
//  .color-#{''+$col},
//  .text-#{''+$col} {
//    color: $val !important;
//  }
//
//  .border-color-#{''+$col} {
//    border-color: $val !important;
//  }
//}
//
//@each $col, $val in $colors {
//  .badge-#{''+$col},
//  .progress-bar-#{''+$col},
//  .bg-#{''+$col} {
//    background-color: $val !important;
//    border-color: $val !important;
//  }
//
//  .bg-#{''+$col}-dark {
//    background-color: darken($val, 10%);
//    border-color: darken($val, 10%);
//  }
//
//  .bg-#{''+$col}-light {
//    background-color: lighten($val, 15%);
//    border-color: lighten($val, 15%);
//  }
//
//  .color-#{''+$col},
//  .text-#{''+$col} {
//    color: $val !important;
//  }
//
//  .border-color-#{''+$col} {
//    border-color: $val !important;
//  }
//}
