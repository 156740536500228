
.ag-theme-alpine .ag-ltr .ag-cell {
  border-right: 1px solid #d9dcde;
}


.ag-root {
  .ag-cell {
    line-height: unset;

    &.ag-cell-inline-editing {
      height: 100%;
      background-color: transparent;
    }


    &.selected-range {
      background: #eb8181 !important;
      border: 2px solid rgba(0, 0, 0, 0.075) !important;
    }

    &.row-span {
      background-color: #fff;
      border-bottom-color: #d9dcde !important;
    }

  }

  .row-disabled {
    color: darken(#ecf0f1, 30%);
    pointer-events: auto ! important;
    cursor: not-allowed ! important;

    fa-icon:not(.fa-spinner) {
      color: var(--ag-row-hover-color, #ecf0f1) !important;
    }
  }

  .text-center,
  .header-tx-center {
    .ag-header-group-cell-label,
    .ag-header-cell-label {
      justify-content: center;
    }
  }

  .text-right {
    text-align: right;
    justify-content: flex-end;

    .ag-header-cell-label {
      justify-content: flex-end;
    }
  }


  .text-left {
    text-align: left;
    justify-content: flex-start;

    .ag-header-cell-label {
      justify-content: flex-start;
    }
  }

  .ag-header-cell-text {
    display: flex;
    align-items: center;
  }


  .text-vertical-align-center {
    display: flex;
    align-items: center;
    justify-content: center;
  }

}


.ag-row {

  &.row-span {
    z-index: 1;
  }

  &.ag-row-selected {
    background-color: var(--ag-row-hover-color, #ecf0f1);

    .row-selected-text-color {
      color: var(--bs-body-color) !important;
    }
  }
}

/****************************************
  CUSTOM STYLES FOR EXCELJS
 ****************************************/
//https://github.com/exceljs/exceljs#fonts
.__strike__true {
  text-decoration: line-through;
}

.__outline__true {
  text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
}

.__bold__true {
  font-weight: bold;
}

.__italic__true {
  font-style: italic;
}

.__underline__true {
  text-decoration: underline;
}

.__underline__true {
  text-decoration: underline;
}


//https://github.com/exceljs/exceljs#alignment

.__horizontal__center {
  display: flex;
  text-align: center;
  justify-content: center;

  input {
    text-align: center;
  }
}

.__horizontal__left {
  text-align: left;

  input {
    text-align: left;

  }
}

.__horizontal__right {
  text-align: right;

  input {
    text-align: right;
  }
}

.__vertical__top {
  vertical-align: top;
}

.__vertical__middle {
  display: flex;
  align-items: center;
  vertical-align: middle;
}

.__vertical__bottom {
  vertical-align: bottom;
}

.__wrapText__true {
}

.__shrinkToFit__true {
}
