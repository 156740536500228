.tag {
  padding: 0.2em 0.6em 0.3em;
  font-weight: 400;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1px;

  &.label-info {
    background: #03a9f3;
  }

  &.label-warning {
    background: $warning
  }

  &.label-primary {
    background: $primary
  }

  &.label-error {
    background: $danger
  }


}

